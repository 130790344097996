import Vue from "vue";
import Router from "vue-router";
import * as URL from "../modules/URLs";

// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: URL.DEMO_YDY,
      name: "demoydy",
      meta: {
        requireAuth: true,
      },
      component(resolve) {
        require(["@/app/demo/ydy"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.DEMO_QJJ,
      name: "demoqjj",
      meta: {
        requireAuth: true,
      },
      component(resolve) {
        require(["@/app/demo/qjj"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.LOAD_NOPMS,
      name: "nopms",
      meta: {
        requireAuth: true,
      },
      component(resolve) {
        require(["@/app/load/nopms"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.LOAD_FAIL,
      name: "fail",
      meta: {
        requireAuth: true,
      },
      component(resolve) {
        require(["@/app/load/fail"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.LOGIN,
      name: "login",
      meta: {
        requireAuth: false,
      },
      component(resolve) {
        require(["@/app/login/login"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.AUTHORITY,
      name: "authority",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "账户权限",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/authority/authority"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.MARKET_MANAGE,
      name: "marketmanage",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "市场",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/marketManage/marketManage"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.MARKET_DETAIL,
      name: "marketdetail",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "市场/",
            path: URL.MARKET_MANAGE,
          },
          {
            name: "市场详情",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/marketManage/marketDetail"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.MARKET_DETAIL_COPY,
      name: "marketdetailcopy",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "市场/",
            path: URL.MARKET_MANAGE,
          },
          {
            name: "市场详情/",
            path: URL.MARKET_DETAIL,
          },

          {
            name: "商品同步",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/marketManage/marketDetailCopy"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.OPERATION_LOG,
      name: "operationlog",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "操作日志",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/operation/operationLog"], (comp) => {
          resolve(comp);
        });
      },
    },

    {
      path: URL.CUSTOMER,
      name: "customer",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "客户",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/customer/customer"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.CUSTOMERDETAIL + "/:id",
      name: "customerDetail",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "客户/",
            path: URL.CUSTOMER,
          },
          {
            name: "客户详情",
            path: URL.CUSTOMERDETAIL,
          },
        ],
      },
      component(resolve) {
        require(["@/app/customer/customerDetail"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.DIYMENU + "/:id",
      name: "diyMenu",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "客户/",
            path: URL.CUSTOMER,
          },
          {
            name: "客户详情 /",
            path: URL.CUSTOMERDETAIL,
          },
          {
            name: "自定义菜单",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/customer/diyMenu"], (comp) => {
          resolve(comp);
        });
      },
    },
    // {
    //   path: URL.CATEGORY + "/:id",
    //   name: "category",
    //   meta: {
    //     requireAuth: true,
    //     appTitle: [
    //       {
    //         name: "客户 / ",
    //         path: URL.CUSTOMER,
    //       },
    //       {
    //         name: "客户详情 / ",
    //         path: "",
    //       },
    //       {
    //         name: "商品品类管理",
    //         path: "",
    //       },
    //     ],
    //   },
    //   component(resolve) {
    //     require(["@/app/marketManage/category"], (comp) => {
    //       resolve(comp);
    //     });
    //   },
    // },
    // {
    //   path: URL.COST_TYPE + "/:id",
    //   name: "costType",
    //   meta: {
    //     requireAuth: true,
    //     appTitle: [
    //       {
    //         name: "客户 / ",
    //         path: URL.CUSTOMER,
    //       },
    //       {
    //         name: "客户详情 / ",
    //         path: "",
    //       },
    //       {
    //         name: "费用类型管理",
    //         path: "",
    //       },
    //     ],
    //   },
    //   component(resolve) {
    //     require(["@/app/marketManage/costType"], (comp) => {
    //       resolve(comp);
    //     });
    //   },
    // },
    // {
    //   path: URL.DIY_MENU + "/:id",
    //   name: "diymenu",
    //   meta: {
    //     requireAuth: true,
    //     appTitle: [
    //       {
    //         name: "客户 / ",
    //         path: URL.CUSTOMER,
    //       },
    //       {
    //         name: "客户详情 / ",
    //         path: "",
    //       },
    //       {
    //         name: "自定义菜单",
    //         path: "",
    //       },
    //     ],
    //   },
    //   component(resolve) {
    //     require(["@/app/customer/diyMenu"], (comp) => {
    //       resolve(comp);
    //     });
    //   },
    // },
    {
      path: URL.PRODUCT,
      name: "product",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "商品库",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/product/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PRODU_CATEGORY,
      name: "produCategory",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "商品分类",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/product/category"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.VERSION,
      name: "version",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "版本管理",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/version/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.NEW_VERSION,
      name: "newVersion",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "版本管理/",
            path: URL.VERSION,
          },
          {
            name: "新建",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/version/new1"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.EDIT_VERSION + "/:id",
      name: "editVersion",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "版本管理/",
            path: URL.VERSION,
          },
          {
            name: "编辑",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/version/edit1"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.VIEW_VERSION + "/:id",
      name: "viewVersion",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "版本管理/",
            path: URL.VERSION,
          },
          {
            name: "查看",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/version/view"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.OEM_VERSION,
      name: "oemVersion",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "版本管理/",
            path: URL.VERSION,
          },
          {
            name: "oem版本管理",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/version/oemSet"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.EquipmentUpgrade,
      name: "equipmentUpgrade",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "设备升级",
            path: URL.EquipmentUpgrade,
          },
        ],
      },
      component(resolve) {
        require(["@/app/equipment/upgrade"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.EquipmentUpgradeAdd,
      name: "equipmentUpgradeAdd",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "设备升级/",
            path: URL.EquipmentUpgrade,
          },
          {
            name: "新增资源",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/equipment/add"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.EquipmentUpgradeAdd + "/:id",
      name: "equipmentUpgradeAdd",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "设备升级/",
            path: URL.EquipmentUpgrade,
          },
          {
            name: "编辑资源",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/equipment/add"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PAYREG,
      name: "payReg",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "支付账户管理",
            path: URL.PAYREG,
          },
        ],
      },
      component(resolve) {
        require(["@/app/payReg/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PAYREG_Add,
      name: "payRegAdd",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "支付账户管理/",
            path: URL.PAYREG,
          },
          {
            name: "新增账户",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/payReg/add"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PAYREG_Edit + "/:id",
      name: "payRegEdit",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "支付账户管理/",
            path: URL.PAYREG,
          },
          {
            name: "编辑账户",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/payReg/add"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PAYREG_Detail + "/:id",
      name: "payRegDetail",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "支付账户管理/",
            path: URL.PAYREG,
          },
          {
            name: "查看详情",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/payReg/detail"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.SCREEN_MANAGE,
      name: "screen_manage",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "数据屏管理",
            path: URL.SCREEN_MANAGE,
          },
        ],
      },
      component(resolve) {
        require(["@/app/screen/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.CHANNEL,
      name: "channel",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "渠道商",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/channel/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.DEVICE,
      name: "device",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "设备管理",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/device/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.VIEW_DEVICE,
      name: "deviceLog",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "设备管理/",
            path: URL.DEVICE,
          },
          {
            name: "查看",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/device/log"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.ANNOUNCEMENT,
      name: "announcement",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "公告管理",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/announcement/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.PROPERTY,
      name: "property",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "招商平台",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/property/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.ANNOUNCEMENT_CREATE,
      name: "announcementcreate",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "公告管理/",
            path: URL.ANNOUNCEMENT,
          },
          {
            name: "新建",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/announcement/create"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.ANNOUNCEMENT_EDIT + "/:id",
      name: "announcementcreate",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "公告管理/",
            path: URL.ANNOUNCEMENT,
          },
          {
            name: "编辑",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/announcement/edit"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.ANNOUNCEMENT_DETAIL + "/:id",
      name: "announcementcreate",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "公告管理/",
            path: URL.ANNOUNCEMENT,
          },
          {
            name: "详情",
            path: "",
          },
        ],
      },
      component(resolve) {
        require(["@/app/announcement/detail"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.MARKET_GROUP,
      name: "market_group",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "市场群组",
            path: URL.MARKET_GROUP,
          },
        ],
      },
      component(resolve) {
        require(["@/app/marketGroup/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.COMPANY_SCREEN,
      name: "company_screen",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "企业大屏管理",
            path: URL.COMPANY_SCREEN,
          },
        ],
      },
      component(resolve) {
        require(["@/app/companyScreen/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.SCREEN_AD,
      name: "screen_ad",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "信发管理",
            path: URL.SCREEN_AD,
          },
        ],
      },
      component(resolve) {
        require(["@/app/screenAd/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.SUPERVISE,
      name: "supervise",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "监管平台管理",
            path: URL.SUPERVISE,
          },
        ],
      },
      component(resolve) {
        require(["@/app/supervise/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.UPLOAD,
      name: "upload",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "文件上传",
            path: URL.UPLOAD,
          },
        ],
      },
      component(resolve) {
        require(["@/app/upload/index"], (comp) => {
          resolve(comp);
        });
      },
    },
    {
      path: URL.MENU,
      name: "menu",
      meta: {
        requireAuth: true,
        appTitle: [
          {
            name: "菜单管理",
            path: URL.MENU,
          },
        ],
      },
      component(resolve) {
        require(["@/app/menu/index"], (comp) => {
          resolve(comp);
        });
      },
    },
  ],
});
